import React, { Component } from "react";
import Image from "next/image";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import "react-multi-carousel/lib/styles.css";
import { Color, Color as Colors } from "../../src/utils/Color";
import { withTheme, useTheme } from "styled-components";
import styled, { keyframes, css } from "styled-components";
import Hidden from "@material-ui/core/Hidden";
import CustomFont from "../../components/CustomFont";
import { maxDevice } from "../../src/utils/breakpoints";
import Router from "next/router";
import * as selectors from "../../src/selectors";
import * as globalActions from "../../src/actions/global";
import { connect } from "dva";
import * as globalSelectors from "../../src/selectors/global";
import _ from "lodash";
import Constants from "../../src/constants";
import Enviroment from "../../src/utils/Environment";
import Divider from "@material-ui/core/Divider";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import Helpers from "../../src/utils/Helpers";
import * as agentSelectors from "../../src/selectors/agent";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BannerCTA from '../PremierAgent/BannerCTA'
import * as profileSelectors from '../../src/selectors/profile'
import Toast from '../../src/utils/Toast'
import { getAgentSubscriptionID } from '../../src/selectors/agent'
import { getAgentAccountSubscriptionAvailable } from '../../src/selectors/profile'
import Link from "@mui/material/Link";
import Marquee from "react-fast-marquee";



const BoxContainer = styled.div`
  border: 2px solid #ddd;
  border-radius: 20px;
  margin-top: -30px;
  //min-height: 950px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-color: ${props =>
    props.isActive
        ? "#8CBD64"
        : "#ddd"};
  background: ${props =>
    props.isActive
        ? "rgb(240, 244, 255)"
        : "white"};
`;
const PackageTitle = styled.div`
  border-radius: 20px;
  background: ${props =>
    props.isActive
        ? "linear-gradient(93deg, #8CBD64 0.57%, #367444 99.35%)"
        : "linear-gradient(93deg, #51bbef 0.57%, #4f7ceb 52.12%, #5059ea 99.35%)"};
  box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.2);
  padding: 0px 20px;
  z-index: 9;
  position: relative;
  display: flex;
  justify-content: center;
  height: 110px;
  align-items: center;
  // animation: ${props => (props.isActive ? shineAnimation : "none")};
  animation: ${props => (props.isActive ? css`${shineAnimation} 4s linear infinite` : "none")};
  
  & p {
    color: ${Colors.textWhite}!important;
    font-weight: 700;
    text-align: center;
    max-width: 200px;
  }

  @media ${maxDevice.md} {
    height: 90px;
  }
`;

const shineAnimation = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const PriceArea = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  height: 100%;
  flex: 1;
`;
const PriceDiscountArea = styled.div`
  position: relative;
  & p {
    font-weight: 700;
  }
`;
const SellPriceText = styled.div`
  font-size: 40px;
  display: flex;
  font-weight: 700;
  color: ${props => props.isActive ? Colors.blackColor : Colors.primaryRed};

`;
const ConcurrentListingText = styled.div`
   font-size: 40px;
   font-weight: 700;
  color: ${Colors.primaryColor}
`;
const FreeConcurrentListingText = styled.div`
   font-size: 40px;
   font-weight: 700;
  color: ${Colors.primaryColor}
`;
const IconCirlceArea = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
`;
const IconCirlceAreaOutter = styled.div`
  background: ${props => props.isActive ? "linear-gradient(93deg, #8CBD64 0.57%, #367444 99.35%)" : "linear-gradient(180deg, #51bbef 0%, #3462f4 100%)"};
  border-radius: 50px;
  padding: 10px;
  margin-right: 20px;
`;
const SubscriptionButton = styled.div`
  border-radius: 6px !important;
  width: 100%;
  padding: 10px 10px !important;
  background: ${Colors.primaryRed};
  width: 200px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  
  @media ${maxDevice.md} {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

const AgentBottomIconContent = styled.div`
    display: flex;
  padding: 5px 0;
`
const IconBottomAreaOutter = styled.div`

`
const IconBottomArea = styled.div`
  position: relative;
  height: 25px;
  width: 25px;
  margin-right: 10px;
`
const PackageBottomIconSection = styled.div`
  display: flex;
  justify-Content: center;
  padding: 20px 0;
  
  @media ${maxDevice.md} {
    flex-direction: column;
    padding: 20px 30px;

  }
`
const CheckBoxIconContent = styled.div`
  align-items: self-start;
    & p {
      font-size: 18px!important;
  }
  @media ${maxDevice.md} {
    & p {
      font-size: 16px !important;
    }
  }
`
const ResponsiveContainerFeature = styled.div`
  display: flex;
  align-items: center;
  //width: 750px;
  justify-content: space-around;
  
  @media ${maxDevice.md} {

  }
`

const ResponsiveContainerFeatureImage = styled.div`
  
  @media ${maxDevice.md} {
    width: 135px!important;
    height: 36px!important;
    margin: 0 !important;
  }
`
const ResponsiveContainerFeatureImageLumi = styled.div`
  
  @media ${maxDevice.md} {
    width: 98px!important;
    height: 36px!important;
  }
`
const ResponsiveContainerFeatureImageStoly = styled.div`
  display: block;
  @media ${maxDevice.md} {
    display: none;
  }
`
const ResponsiveContainerFeatureImageStolyMobile = styled.div`
  display: none;
  @media ${maxDevice.md} {
    display: block;
  }
`

class AgentPackageFeatured extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    // onClickSubscription = (e, value) => {
    //   const { onClickSubscription, checkAccessAgent } = this.props;
    //   onClickSubscription && onClickSubscription(e, value, checkAccessAgent);
    // };

    onClickSubscription = (e, value, name) => {

        const { postAgentSubscriptionPackage, checkAccessAgent } = this.props;

        postAgentSubscriptionPackage && postAgentSubscriptionPackage(value, name, checkAccessAgent);
    };

    onClickToastMsg = () => {
        Toast.info("Only Agent Can Be Proceed");
    };

    render() {

        return (
           <div>
               <Container style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                   <div
                       style={{
                           display: "flex",
                           flexDirection: "column",
                           alignItems: "center"
                       }}
                   >
                       <CustomFont
                           size="xxlarge"
                           styles={{
                               color: Colors.blackColor,
                               fontWeight: "700",
                               textAlign: "center"
                           }}
                       >
                           As Featured in
                       </CustomFont>
                       <Divider
                           style={{
                               marginTop: "10px",
                               marginBottom: "30px",
                               width: "47px",
                               height: "3px",
                               background: Colors.primaryColor,
                               opacity: 1,
                               borderRadius: "6px"
                           }}
                       />
                       {/*<ResponsiveContainerFeature>*/}
                       {/*    <ResponsiveContainerFeatureImage*/}
                       {/*        style={{*/}
                       {/*            position: "relative",*/}
                       {/*            height: "86px",*/}
                       {/*            width: "194px",*/}
                       {/*            margin: "0px 20px"*/}
                       {/*        }}*/}
                       {/*    >*/}
                       {/*        <a href="https://thesun.my/business/property-genie-helps-malaysians-through-their-real-estate-journeys-EA11777982" target="_blank" rel="noopener noreferrer">*/}
                       {/*        <Image*/}
                       {/*            objectFit="contain"*/}
                       {/*            src={"/premier-agent/the-sun-corp.png"}*/}
                       {/*            layout="fill"*/}
                       {/*            alt="the-sun"*/}
                       {/*        />*/}
                       {/*        </a>*/}
                       {/*    </ResponsiveContainerFeatureImage>*/}
                       {/*     <ResponsiveContainerFeatureImage*/}
                       {/*        style={{*/}
                       {/*            position: "relative",*/}
                       {/*            height: "86px",*/}
                       {/*            width: "315px",*/}
                       {/*            margin: "0px 20px"*/}
                       {/*        }}*/}
                       {/*    >*/}
                       {/*         <a href="https://www.sinardaily.my/article/212999/malaysia/how-property-genie-helps-malaysians-through-their-real-estate-journeys" target="_blank" rel="noopener noreferrer">*/}
                       {/*        <Image*/}
                       {/*            objectFit="contain"*/}
                       {/*            src={"/premier-agent/sinar-daily-corp.png"}*/}
                       {/*            layout="fill"*/}
                       {/*            alt="sinar-daily"*/}
                       {/*        />*/}
                       {/*         </a>*/}
                       {/*    </ResponsiveContainerFeatureImage>*/}
                       {/*</ResponsiveContainerFeature>*/}
                       {/*<br/>*/}
                       {/*<ResponsiveContainerFeature>*/}
                       {/*    <ResponsiveContainerFeatureImageLumi*/}
                       {/*        style={{*/}
                       {/*            position: "relative",*/}
                       {/*            height: "86px",*/}
                       {/*            width: "228px",*/}
                       {/*            margin: "0px 20px"*/}
                       {/*        }}*/}
                       {/*    >*/}
                       {/*        <a href="https://luminews.my/news/1767621" target="_blank" rel="noopener noreferrer">*/}
                       {/*        <Image*/}
                       {/*            objectFit="contain"*/}
                       {/*            src={"/premier-agent/lumi-corp.png"}*/}
                       {/*            layout="fill"*/}
                       {/*            alt="lumi-corp"*/}
                       {/*        />*/}
                       {/*        </a>*/}
                       {/*    </ResponsiveContainerFeatureImageLumi>*/}
                       {/*    <ResponsiveContainerFeatureImage*/}
                       {/*        style={{*/}
                       {/*            position: "relative",*/}
                       {/*            height: "86px",*/}
                       {/*            width: "320px",*/}
                       {/*            margin: "0px 20px"*/}
                       {/*        }}*/}
                       {/*    >*/}
                       {/*        <a href="https://newswav.com/article/property-genie-helps-malaysians-through-their-real-estate-journeys-A2311_zLnz9X" target="_blank" rel="noopener noreferrer">*/}
                       {/*        <Image*/}
                       {/*            objectFit="contain"*/}
                       {/*            src={"/premier-agent/newswav-corp.png"}*/}
                       {/*            layout="fill"*/}
                       {/*            alt="newswav"*/}
                       {/*        />*/}
                       {/*        </a>*/}
                       {/*    </ResponsiveContainerFeatureImage>*/}
                       {/*    <ResponsiveContainerFeatureImageStoly*/}
                       {/*        style={{*/}
                       {/*            position: "relative",*/}
                       {/*            height: "86px",*/}
                       {/*            width: "181px",*/}
                       {/*            margin: "0px 20px"*/}
                       {/*        }}*/}
                       {/*    >*/}
                       {/*        <a href="https://thestoly.com/2023/11/your-dream-home-is-their-command-how-property-genie-helps-malaysians-through-their-real-estate-journeys/" target="_blank" rel="noopener noreferrer">*/}
                       {/*        <Image*/}
                       {/*            objectFit="contain"*/}
                       {/*            src={"/premier-agent/the-stoly-corp.png"}*/}
                       {/*            layout="fill"*/}
                       {/*            alt="stoly"*/}
                       {/*        />*/}
                       {/*        </a>*/}
                       {/*    </ResponsiveContainerFeatureImageStoly>*/}
                       {/*</ResponsiveContainerFeature>*/}
                       {/*<ResponsiveContainerFeatureImageStolyMobile*/}
                       {/*    style={{*/}
                       {/*        position: "relative",*/}
                       {/*        height: "48px",*/}
                       {/*        width: "126px",*/}
                       {/*        margin: "0px 20px",*/}
                       {/*        marginTop: "20px"*/}
                       {/*    }}*/}
                       {/*>*/}
                       {/*    <a href="https://thesun.my/business/property-genie-helps-malaysians-through-their-real-estate-journeys-EA11777982" target="_blank" rel="noopener noreferrer">*/}
                       {/*    <Image*/}
                       {/*        objectFit="contain"*/}
                       {/*        src={"/premier-agent/the-stoly-corp.png"}*/}
                       {/*        layout="fill"*/}
                       {/*        alt="stoly"*/}
                       {/*    />*/}
                       {/*    </a>*/}
                       {/*</ResponsiveContainerFeatureImageStolyMobile>*/}

                       {/*// ======= For latest Updated ========*/}

                       <Marquee pauseOnHover>
                           <ResponsiveContainerFeature>
                               <ResponsiveContainerFeatureImage
                                   style={{
                                       position: "relative",
                                       height: "86px",
                                       width: "194px",
                                       margin: "0px 30px"
                                   }}
                               >
                                   <a href="https://thesun.my/business/property-genie-helps-malaysians-through-their-real-estate-journeys-EA11777982" target="_blank" rel="noopener noreferrer">
                                   <Image
                                       objectFit="contain"
                                       src={"/premier-agent/the-sun-corp.png"}
                                       layout="fill"
                                       alt="the-sun"
                                   />
                                   </a>
                               </ResponsiveContainerFeatureImage>
                                <ResponsiveContainerFeatureImage
                                   style={{
                                       position: "relative",
                                       height: "86px",
                                       width: "315px",
                                       margin: "0px 30px"
                                   }}
                               >
                                    <a href="https://www.sinardaily.my/article/212999/malaysia/how-property-genie-helps-malaysians-through-their-real-estate-journeys" target="_blank" rel="noopener noreferrer">
                                   <Image
                                       objectFit="contain"
                                       src={"/premier-agent/sinar-daily-corp.png"}
                                       layout="fill"
                                       alt="sinar-daily"
                                   />
                                    </a>
                               </ResponsiveContainerFeatureImage>
                           </ResponsiveContainerFeature>
                           {/*<br/>*/}
                           <ResponsiveContainerFeature>
                               <ResponsiveContainerFeatureImageLumi
                                   style={{
                                       position: "relative",
                                       height: "86px",
                                       width: "228px",
                                       margin: "0px 30px"
                                   }}
                               >
                                   <a href="https://luminews.my/news/1767621" target="_blank" rel="noopener noreferrer">
                                   <Image
                                       objectFit="contain"
                                       src={"/premier-agent/lumi-corp.png"}
                                       layout="fill"
                                       alt="lumi-corp"
                                   />
                                   </a>
                               </ResponsiveContainerFeatureImageLumi>
                               <ResponsiveContainerFeatureImage
                                   style={{
                                       position: "relative",
                                       height: "86px",
                                       width: "320px",
                                       margin: "0px 30px"
                                   }}
                               >
                                   <a href="https://newswav.com/article/property-genie-helps-malaysians-through-their-real-estate-journeys-A2311_zLnz9X" target="_blank" rel="noopener noreferrer">
                                   <Image
                                       objectFit="contain"
                                       src={"/premier-agent/newswav-corp.png"}
                                       layout="fill"
                                       alt="newswav"
                                   />
                                   </a>
                               </ResponsiveContainerFeatureImage>
                               <ResponsiveContainerFeatureImageStoly
                                   style={{
                                       position: "relative",
                                       height: "86px",
                                       width: "181px",
                                       margin: "0px 30px"
                                   }}
                               >
                                   <a href="https://thestoly.com/2023/11/your-dream-home-is-their-command-how-property-genie-helps-malaysians-through-their-real-estate-journeys/" target="_blank" rel="noopener noreferrer">
                                   <Image
                                       objectFit="contain"
                                       src={"/premier-agent/the-stoly-corp.png"}
                                       layout="fill"
                                       alt="stoly"
                                   />
                                   </a>
                               </ResponsiveContainerFeatureImageStoly>
                           </ResponsiveContainerFeature>
                           <ResponsiveContainerFeatureImageStolyMobile
                               style={{
                                   position: "relative",
                                   height: "48px",
                                   width: "126px",
                                   margin: "0px 20px",
                                   marginRight: "0"
                                   // marginTop: "20px"
                               }}
                           >
                               <a href="https://thesun.my/business/property-genie-helps-malaysians-through-their-real-estate-journeys-EA11777982" target="_blank" rel="noopener noreferrer">
                               <Image
                                   objectFit="contain"
                                   src={"/premier-agent/the-stoly-corp.png"}
                                   layout="fill"
                                   alt="stoly"
                               />
                               </a>
                           </ResponsiveContainerFeatureImageStolyMobile>
                       </Marquee>
                       <br/>
                            {/*// ========= For A Malaysia Digital Partner ========*/}
                       <CustomFont
                           size="xxlarge"
                           styles={{
                               color: Colors.blackColor,
                               fontWeight: "700",
                               textAlign: "center"
                           }}
                       >
                           A Malaysia Digital Partner
                       </CustomFont>
                       <Divider
                           style={{
                               marginTop: "10px",
                               marginBottom: "30px",
                               width: "47px",
                               height: "3px",
                               background: Colors.primaryColor,
                               opacity: 1,
                               borderRadius: "6px"
                           }}
                       />
                       <ResponsiveContainerFeature>
                           <ResponsiveContainerFeatureImage
                               style={{
                                   position: "relative",
                                   height: "86px",
                                   width: "194px",
                                   margin: "0px 20px"
                               }}
                           >
                               <Image
                                   objectFit="contain"
                                   src={"/images/malaysia-digital-cropped.png"}
                                   layout="fill"
                                   alt="malaysia-digital"
                               />
                           </ResponsiveContainerFeatureImage>
                       </ResponsiveContainerFeature>

                   </div>
               </Container>
           </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        agentSubscriptionItems: selectors.getAgentCommonDataOptionsAgentSubscriptions(
            state
        ),
        agentCommonData: selectors.getAgentCommonDataEnum(state),
        meData: selectors.getProfileData(state),
        isAgent: selectors.getAuthAgentToken(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default withTheme(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AgentPackageFeatured)
);
