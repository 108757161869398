import styled from "styled-components";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CustomFont from "../../CustomFont";
import { Color } from "../../../src/utils/Color";
import Button from "@material-ui/core/Button";
import Image from "next/image";
import Hidden from "@material-ui/core/Hidden";
import { maxDevice } from "../../../src/utils/breakpoints";
import React from "react";

const AgentBannerContainer = styled.div`
  background-image: linear-gradient(to right, #355cee, #3721b4);
  border-radius: 20px;
  padding: 30px 40px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const AgentBannerMobileContainer = styled.div`
  background-image: linear-gradient(to right, #355cee, #3721b4);
  border-radius: 10px;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BannerCTA = ({onClickAgentSignUp}) => {
  return (
    <Container style={{ marginTop: "4rem", marginBottom: "2rem" }}>
      <Hidden smDown>
        <Grid item xs={12}>
          <AgentBannerContainer>
            <CustomFont
              styles={{
                color: "white",
                fontWeight: "600",
                flex: 1,
                display: "flex",
                textAlign: "left"
              }}
              size="xxlarge"
            >
                Limited slots available. <br /> Start posting your listings with Property Genie
            </CustomFont>
              <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                padding: "10px 20px",
                fontSize: "16px",
                background: "white",
                color: Color.primaryColor,
                fontWeight: "600",
                borderRadius: "10px",
                marginTop: "20px"
              }}
              size="small"
              onClick={onClickAgentSignUp}
            >
                Free-31 Day Package
            </Button>
              <CustomFont
                  size="large"
              styles={{ color: Color.yellowColor, fontWeight:"600", marginTop:"5px"}}
              >
                  Subscribe Now
              </CustomFont>
              </div>
          </AgentBannerContainer>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid item xs={12}>
          <AgentBannerMobileContainer>
            <CustomFont
              styles={{
                color: "white",
                fontWeight: "600",
                flex: 1,
                display: "flex",
                textAlign: "center"
              }}
              size="large"
            >
                Limited slots available. <br /> Start posting your listings with Property Genie
            </CustomFont>
              <div style={{textAlign: "center"}}>
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                padding: "10px 20px",
                fontSize: "16px",
                background: "white",
                color: Color.primaryColor,
                fontWeight: "600",
                borderRadius: "10px",
                marginTop: "20px"
              }}
              size="small"
              onClick={onClickAgentSignUp}
            >
                Free-31 Day Package
            </Button>
              <CustomFont
                  size="large"
                  styles={{ color: Color.yellowColor, fontWeight:"600", marginTop:"5px"}}
              >
                  Subscribe Now
              </CustomFont>
              </div>
          </AgentBannerMobileContainer>
        </Grid>
      </Hidden>
    </Container>
  );
};

export default BannerCTA;
